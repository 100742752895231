import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../components/theme-provider';
import { css } from '@emotion/react';
import { Share } from './icons';
import PopupShare from './popup-share';

export default function CollectionHeader({ className, monoText, title }) {
  const [sharePopup, setSharePopup] = useState(false);
  const [titleField, setTitleField] = useState(title);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    theme.setCollectionName(titleField);
  }, [titleField]);

  return (
    <div className={className}>
      <h2
        className="type--mono-heading"
        css={css`
          margin-bottom: 2.1rem;

          @media (max-width: 800px) {
            margin-bottom: 1rem;
          }

          @media (max-width: 550px) {
            margin-bottom: 1.3rem;
          }
        `}
      >
        {monoText}
      </h2>
      <div
        css={css`
          position: relative;
          display: flex;
          justify-content: space-between;
        `}
      >
        <h1
          className="type--heading-four type--underline"
          css={css`
            flex-grow: 1;
            padding-right: var(--gutter);

            @media (max-width: 800px) {
              padding-right: var(--gutterHalf);
            }
          `}
        >
          <div
            css={css`
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                display: block;
                width: 4rem;
                background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
              }
            `}
          >
            <input
              type="text"
              name="collection"
              value={titleField}
              onChange={(e) => {
                setTitleField(e.target.value);
              }}
              css={css`
                width: 100%;
                line-height: 1;
                padding: 0;
                border: none !important;
              `}
            />
          </div>
        </h1>
        <div
          className="type--body-large"
          css={css`
            display: flex;
            align-items: center;

            @media (max-width: 550px) {
              display: block;
            }
          `}
        >
          <Share
            css={css`
              order: 1;
              margin-left: 1.5rem;

              @media (max-width: 550px) {
                margin-bottom: 0.8rem;
                margin-left: 0;
              }
            `}
            onClick={() => setSharePopup(true)}
          />
          <span>Share</span>
        </div>
        <PopupShare
          title={titleField}
          invert={true}
          swapClosePosition={true}
          hash={`#${encodeURI(theme.collectionName)}&${[...theme.collection].join(',')}`}
          css={css`
            display: ${sharePopup ? 'block' : 'none'};
            top: -2rem;
            z-index: 1;
          `}
          closeOnClick={() => setSharePopup(false)}
        />
      </div>
    </div>
  );
}

import { graphql } from 'gatsby';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import { format } from 'date-fns';
import SEO from '../components/seo';
import FilterBar from '../components/filter-bar';
import FilterPanel from '../components/filter-panel';
import SortButton from '../components/sort-button';
import { Save } from '../components/icons';
import Grid from '../components/grid';
import Box from '../components/box';
import Card from '../components/card';
import CollectionHeader from '../components/collection-header';
import CaptionCard from '../components/caption-card';
import PagePaddingTop from '../components/page-padding-top';

export default function Collection({ data }) {
  const [displayCaptionCard, setDisplayCaptionCard] = useState(true);
  const [results, setResults] = useState([]);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/work');
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.slice(1);
      const data = hash.split('&');

      theme.setCollectionName(decodeURI(data[0]));
      theme.setCollection(new Set(decodeURI(data[1]).split(',')));

      window.history.replaceState('', document.title, window.location.href.replace(/#.*$/, ''))
    }
  }, []);

  useLayoutEffect(() => {
    setResults([...theme.collection]);
  }, [theme.collection]);

  return (
    <PagePaddingTop>
      <SEO title="Collection" />
      {theme.collection.size > 0 && <>
        <Box
          x={10}
          y={3}
        >
          <CollectionHeader 
            monoText="Custom Collection"
            title={theme.collectionName}
          />
        </Box>
        <Box
          x={10}
          y={6}
        >
          <Grid
            css={css`
              padding-bottom: 6rem;

              > div,
              a {
                grid-column-start: span 4;
  
                @media (max-width: 1024px) {
                  grid-column-start: span 6;
                }
  
                @media (max-width: 550px) {
                  grid-column-start: span 8;
                }
  
                img {
                  aspect-ratio: 387/380;
                }
              }
            `}
          >
            {results.map((result, i) => {
              if (data.projects.nodes.find((node) => node.id === result)) {
                const project = data.projects.nodes.find((node) => node.id === result);
  
                return (
                  <div
                    key={i}
                  >
                    <Card
                      url={project.comingSoon ? null : `/project/${project.slug.current}`}
                      image={project.featuredImage}
                      imageCrop={true}
                      saveButton={project.id}
                      monoText={project.discipline
                          .map((discipline) => discipline.title)
                          .sort()
                          .join(', ')}
                      text={project.title}
                      hoverText={project.comingSoon ? <div>Case study coming soon</div> : null}
                    />
                  </div>
                );
              } else if (data.articles.nodes.find((node) => node.id === result)) {
                const article = data.articles.nodes.find((node) => node.id === result);
  
                return (
                  <div
                    key={i}
                  >
                    <Card
                      url={article.url ? article.url : `/article/${article.slug.current}`}
                      external={article.externalArticle}
                      externalTitle={article.publication}
                      image={article.featuredImage}
                      imageCrop={true}
                      saveButton={article.id}
                      monoText={`${article.tag
                        .map((tag) => tag.title)
                        .sort()
                        .join(', ')} — ${format(new Date(article.datetime), 'MMMM d, yyyy')}`}
                      text={article.title}
                    />
                  </div>
                );
              } else if (data.people.nodes.find((node) => node.id === result)) {
                const person = data.people.nodes.find((node) => node.id === result);
  
                return (
                  <div
                    key={i}
                  >
                    <Card
                      url={person.singlePage ? `/person/${person.slug.current}` : null}
                      image={person.image}
                      imageCrop={true}
                      saveButton={person.id}
                      monoText={person.position}
                      text={person.name}
                    />
                  </div>
                );
              }
            })}
          </Grid>
        </Box>
      </>}
      {theme.collection.size === 0 && <div 
        className="type--heading-four"
        css={css`
          width: 100%;
          height: calc(100vh - var(--marginPageTop));
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: var(--marginPageTop);
          p {
            margin-bottom: var(--margin3);
            &:last-child {
              margin-bottom: 0;
            }
          }
        `}
      >
        <Box
          x={10}
          y={10}
        >
          <Grid
            columns={6}
          >
            <div
              css={css`
                text-align: center;
              `}
            >
              <p>You do not currently have anything saved.</p>
              {<p>Look out for the
                <Save 
                  css={css`
                    display: inline-block;
                    margin: 0 1rem;
                    transform: translateY(0.7rem);
                    pointer-events: none;
                  `}
                />
                 to save <a href="/work">Projects</a> or <a href="/news">Articles</a>.
              </p>}
            </div>
          </Grid>
        </Box>
      </div>}
      <CaptionCard
        text="Collections give you ability to create a collection of our work that is most relevant to you. You can then share that collection with whoever you like!"
        closeButton={true}
        closeButtonOnClick={() => setDisplayCaptionCard(false)}
        css={css`
          display: ${displayCaptionCard ? 'block' : 'none'};
          position: fixed !important;
          bottom: var(--gutter) !important;
          left: var(--gutter) !important;
          margin: 0 !important;
  
          @media (max-width: 550px) {
            bottom: var(--gutterHalf) !important;
            left: var(--gutterHalf) !important;
          }
        `}
      />
    </PagePaddingTop>
  );
}

export const query = graphql`
  query {
    projects: allSanityProject(
      filter: { slug: { current: { ne: null } } }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        featuredImage {
          ...Image
        }
        discipline {
          title
          slug {
            current
          }
        }
        comingSoon
      }
    }
    articles: allSanityArticle(
      filter: { slug: { current: { ne: null } } }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        externalArticle
        url
        publication
        datetime
        featuredImage {
          ...Image
        }
        tag {
          title
          slug {
            current
          }
        }
      }
    }
    people: allSanityPerson(filter: { active: { eq: true } }) {
      nodes {
        id
        name
        slug {
          current
        }
        singlePage
        image {
          ...Image
        }
        position
        role {
          title
          slug {
            current
          }
        }
        office {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
